import React, { Component } from 'react';
import { Container, Button} from 'react-bootstrap';
import NavigationBar from '../../NavigationBar';
import ChatGPTQueries from './EditPetComponents/ChatGPTQueries';
import EditPetForm from './EditPetComponents/EditPetForm';
import { API } from '../variables/variables';
import Cookies from 'js-cookie';

class EditPet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pet_id : this.props.pet_id,
            formSelected: true,
            petName: ''
        }
        this.switch = this.switch.bind(this);
    }

    async switch() {
        this.setState({formSelected : !this.state.formSelected});
        if(this.props.pet_id === "-1"){
            console.log("new pet");
        }
        else{
            // Get the pet information to edit.
            let current_pet_response = await fetch(API + '/pet?id=' + this.props.pet_id, {
                method: 'GET',
                headers: {
                    'Authorization' : 'Bearer ' + Cookies.get('pqsToken')
                }
            });
            if(current_pet_response.ok){
                const data = await current_pet_response.json();
                const pet = data.data;
                if(pet === null || pet === undefined) {
                    this.setState({petIDNotValid : true});
                }
                else{
                    /* Setting Overview Section */
                    this.setState({petName : pet.name});
                }
            }
        }
    }

    render() {
        return (
            <div>
                <NavigationBar />
                <Container>
                    <h1>Pet Quick Start Edit Pet Form</h1>
                    <br></br>
                    <Button variant="dark" href="/">Back</Button>
                    <div className="title-buttons-container">
                        <div className={this.state.formSelected?"center title-button-active" : "center title-button"} onClick={this.switch}>
                            <h2>Form</h2>
                        </div>
                        <div className={this.state.formSelected?"center title-button" : "center title-button-active"} onClick={this.switch}>
                            <h2>Queries</h2>
                        </div>
                    </div>
                    <br></br>

                    <div className={this.state.formSelected ? "" : "hide"}>
                        <EditPetForm pet_id={this.state.pet_id}/>
                    </div>
                    <div className={this.state.formSelected ? "hide" : ""}>
                        <ChatGPTQueries petName={this.state.petName}/>
                    </div>
                </Container>
            </div>
        )
    }
}

export default EditPet;