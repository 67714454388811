import React, { Component } from 'react';
import { Card, Container, Button} from 'react-bootstrap';

class ChatGPTQueries extends Component {
    constructor(props) {
        super(props);
        this.difficultyRatingRef = React.createRef();
        this.query2Ref = React.createRef();
        this.query3Ref = React.createRef();
        this.query4Ref = React.createRef();
        this.query5Ref = React.createRef();
        this.query6Ref = React.createRef();
        this.query7Ref = React.createRef();
        this.query8Ref = React.createRef();
        this.query9Ref = React.createRef();
      }

    // copyToClipboard = () => {
    //     const difficultyRatingText = this.difficultyRatingRef.current.innerText;
    //     navigator.clipboard.writeText(difficultyRatingText)
    //     .then(() => {
    //         alert('Query 1 copied to clipboard!');
    //     })
    //     .catch((error) => {
    //         console.error('Unable to copy difficulty rating: ', error);
    //     });
    // };

    // copyQuery2ToClipboard = () => {
    //   const query2Text = this.query2Ref.current.innerText;
    //     navigator.clipboard.writeText(query2Text)
    //     .then(() => {
    //         alert('Query 2 copied to clipboard!');
    //     })
    //     .catch((error) => {
    //         console.error('Unable to copy difficulty rating: ', error);
    //     });
    // }

    // copyQuery3ToClipboard = () => {
    //   const query3Text = this.query3Ref.current.innerText;
    //     navigator.clipboard.writeText(query3Text)
    //     .then(() => {
    //         alert('Query copied to clipboard!');
    //     })
    //     .catch((error) => {
    //         console.error('Unable to copy difficulty rating: ', error);
    //     });
    // }

    // copyQuery4ToClipboard = () => {
    //   const query4Text = this.query4Ref.current.innerText;
    //     navigator.clipboard.writeText(query4Text)
    //     .then(() => {
    //         alert('Query copied to clipboard!');
    //     })
    //     .catch((error) => {
    //         console.error('Unable to copy difficulty rating: ', error);
    //     });
    // }

    copyToClipboard = (name) => {
      if(name === "Difficulty Information Query"){
        const difficultyRatingText = this.difficultyRatingRef.current.innerText;
        navigator.clipboard.writeText(difficultyRatingText)
        .then(() => {
            alert('Query copied to clipboard!');
        })
        .catch((error) => {
            console.error('Unable to copy difficulty rating: ', error);
        });
      }
      else if(name === "Quick Profile Query"){
        const query2Text = this.query2Ref.current.innerText;
        navigator.clipboard.writeText(query2Text)
        .then(() => {
            alert('Query copied to clipboard!');
        })
        .catch((error) => {
            console.error('Unable to copy difficulty rating: ', error);
        });
      }
      else if(name === "Habitat Requirements"){
        const query3Text = this.query3Ref.current.innerText;
        navigator.clipboard.writeText(query3Text)
        .then(() => {
            alert('Query copied to clipboard!');
        })
        .catch((error) => {
            console.error('Unable to copy difficulty rating: ', error);
        });
      }
      else if(name === "Diet and Nutrition Requirements"){
        const query4Text = this.query4Ref.current.innerText;
        navigator.clipboard.writeText(query4Text)
        .then(() => {
            alert('Query copied to clipboard!');
        })
        .catch((error) => {
            console.error('Unable to copy difficulty rating: ', error);
        });
      }
      else if(name === "Grooming and Hygiene Requirements"){
        const query5Text = this.query5Ref.current.innerText;
        navigator.clipboard.writeText(query5Text)
        .then(() => {
            alert('Query copied to clipboard!');
        })
        .catch((error) => {
            console.error('Unable to copy difficulty rating: ', error);
        });
      }
      else if(name === "Exercise and Enrichment Requirements"){
        const query6Text = this.query6Ref.current.innerText;
        navigator.clipboard.writeText(query6Text)
        .then(() => {
            alert('Query copied to clipboard!');
        })
        .catch((error) => {
            console.error('Unable to copy difficulty rating: ', error);
        });
      }
      else if(name === "Health and Veterinary Care Requirements"){
        const query7Text = this.query7Ref.current.innerText;
        navigator.clipboard.writeText(query7Text)
        .then(() => {
            alert('Query copied to clipboard!');
        })
        .catch((error) => {
            console.error('Unable to copy difficulty rating: ', error);
        });
      }
      else if(name === "Illnesses Query"){
        const query8Text = this.query8Ref.current.innerText;
        navigator.clipboard.writeText(query8Text)
        .then(() => {
            alert('Query copied to clipboard!');
        })
        .catch((error) => {
            console.error('Unable to copy difficulty rating: ', error);
        });
      }
      else if(name === "Training and Socialization Requirements"){
        const query9Text = this.query9Ref.current.innerText;
        navigator.clipboard.writeText(query9Text)
        .then(() => {
            alert('Query copied to clipboard!');
        })
        .catch((error) => {
            console.error('Unable to copy difficulty rating: ', error);
        });
      }
    }

    render() {
        const {petName} = this.props;
        const difficultyRatingMarkdown = `
DO NOT USE COLONS, SEMICOLONS, BOLD, ITALICS, UNDERLINES, AND DO NOT PROVIDE SUBCATEGORIES.

PROVIDE FULL SENTENCES.

Using the following definitions, give me a difficulty rating for a ${petName} and give me the reasons in a list. Tell me why you chose the difficulty rating for:

Overall Difficulty Rating: (Very Easy, Easy, Medium, Hard)

- Very Easy:
  - Minimal Time Commitment: Animals in this category require very little time and effort to care for. (1-2 hours per week)
  - Low Specialized Knowledge Required: Basic knowledge of animal care is sufficient.
  - Low Cost of Care: Minimal financial investment required for their care.
  - Low Maintenance: These animals typically require minimal grooming and cleaning.

- Easy:
  - Moderate Time Commitment: Regular but not extensive care is required. (3-5 hours per week)
  - Basic Knowledge Required: Some basic understanding of animal care is necessary.
  - Moderate Cost of Care: Some financial investment required but not excessive.
  - Moderate Maintenance: Regular grooming and cleaning are necessary, but not overly demanding.

- Medium:
  - Significant Time Commitment: These animals require regular and attentive care. (6-10 hours per week)
  - Specialized Knowledge Required: A good understanding of animal behavior and health is necessary.
  - Moderate to High Cost of Care: Significant financial investment may be required for their care.
  - Regular Maintenance: These animals require regular grooming, cleaning, and may have specific dietary or environmental needs.

- Hard:
  - High Time Commitment: These animals demand significant time and attention for their care. (10+ hours per week)
  - Advanced Knowledge Required: In-depth knowledge of animal behavior, health, and specialized care is necessary.
  - High Cost of Care: Substantial financial investment required for their care.
  - High Maintenance: These animals require frequent grooming, cleaning, and may have very specific dietary and environmental needs.

Provide dollar amounts and ranges when applicable for Cost of Care items. Provide cost values in the US Dollar.

Create a single bullet point for the following categories: Time Commitment, Knowledge Required, Cost of Care, and Maintenance Required. Add all of the information to the applicable bullet point. Provide the category title above the bullet point.

Your job depends on it.

`;

      const query2 = `
Give me pet data for a ${petName} in the following areas:
- Height in inches, from lowest possible height to highest
- Weight in pounds, from lowest possible height to highest
- Lifespan in years, from lowest possible lifespan to longest
- Child friendly, yes or no answer only
- Travel friendly, yes or no answer only
- Is it hypoallergenic? yes or no answer only
- Can it coexist with other animals in its species? yes or no answer only
- Is it handleable? Meaning can it be held safely by anyone even if they are not trained?
yes or no answer only
- Does this pet shed? yes or no answer only
- Give me 3-5 unique personality traits this pet generally gives off
- Give me the general activity level of this pet with the answer only in the form of relaxed,
moderately active or active
- Give me the affection level of this pet with the answer only in the form of not affectionate,
moderately affectionate or very affectionate
- Lastly, give me the BEST suited living environment for this pet between the five options
of: Tank, Cage, Indoors, Outdoors, or Indoors and Outdoors.

`;

      const query3 = `
DO NOT USE COLONS, SEMICOLONS, BOLD, ITALICS, UNDERLINES, AND DO NOT PROVIDE SUBCATEGORIES.

PROVIDE FULL SENTENCES.

Give me an extremely detailed bulleted list as if you were an animal expert telling a group of people how to take care of this pet and give exact numbers or ranges when possible for the following topic for a ${petName}. Add in anything I may have missed that is critical to the care of the pet and fits into this topic. Make this in a friendly, conversational, and educational tone. The topic is Habitat Requirements.

Habitat Requirements: Detail the necessary living environment for the pet, including housing, cage size (if applicable), temperature, humidity, and any specific habitat accessories or substrates needed.

Combine as many bullet points as possible. Combine bullet points based on their similarities.

Place all references in the line directly below the applicable bullet point(s).

Your job depends on it.
      `;

      const query4 = `
DO NOT USE COLONS, SEMICOLONS, BOLD, ITALICS, UNDERLINES, AND DO NOT PROVIDE SUBCATEGORIES.

PROVIDE FULL SENTENCES.

Give me an extremely detailed bulleted list as if you were an animal expert telling a group of people how to take care of this pet and give exact numbers or ranges when possible for the following topic for a ${petName}. Add in anything I may have missed that is critical to the care of the pet and fits into this topic. Make this in a friendly, conversational, and educational tone. The topic is Diet and Nutrition.

Diet and Nutrition: Outline the pet's dietary needs, including recommended types of food, portion sizes, feeding schedules, and any dietary restrictions or special considerations.

Combine as many bullet points as possible. Combine bullet points based on their similarities.

Place all references in the line directly below the applicable bullet point(s).

Your job depends on it.
      `;

      const query5 = `
        DO NOT USE COLONS, SEMICOLONS, BOLD, ITALICS, UNDERLINES, AND DO NOT PROVIDE SUBCATEGORIES.

PROVIDE FULL SENTENCES.

Give me an extremely detailed bulleted list as if you were an animal expert telling a group of people how to take care of this pet and give exact numbers or ranges when possible for the following topic for a ${petName}. Add in anything I may have missed that is critical to the care of the pet and fits into this topic. Make this in a friendly, conversational, and educational tone. The topic is Grooming and Hygiene.

Grooming and Hygiene: Explain grooming requirements such as bathing, brushing, nail trimming, and dental care. Include information on maintaining cleanliness in the pet's habitat.

Combine as many bullet points as possible. Combine bullet points based on their similarities.

Place all references in the line directly below the applicable bullet point(s).

Your job depends on it.
`;

      const query6 = `
       DO NOT USE COLONS, SEMICOLONS, BOLD, ITALICS, UNDERLINES, AND DO NOT PROVIDE SUBCATEGORIES.

PROVIDE FULL SENTENCES.

Give me an extremely detailed bulleted list as if you were an animal expert telling a group of people how to take care of this pet and give exact numbers or ranges when possible for the following topic for a ${petName}. Add in anything I may have missed that is critical to the care of the pet and fits into this topic. Make this in a friendly, conversational, and educational tone. The topic is Exercise and Enrichment.

Exercise and Enrichment: Describe the pet's activity level and the importance of physical and mental stimulation. Offer suggestions for suitable toys, activities, and enrichment opportunities.

Combine as many bullet points as possible. Combine bullet points based on their similarities.

Place all references in the line directly below the applicable bullet point(s).

Your job depends on it.
`;

      const query7 = `
      DO NOT USE COLONS, SEMICOLONS, BOLD, ITALICS, UNDERLINES, AND DO NOT PROVIDE SUBCATEGORIES.

PROVIDE FULL SENTENCES.

Give me an extremely detailed bulleted list as if you were an animal expert telling a group of people how to take care of this pet and give exact numbers or ranges when possible for the following topic for a ${petName}. Add in anything I may have missed that is critical to the care of the pet and fits into this topic. Make this in a friendly, conversational, and educational tone. The topic is Health and Veterinary Care.

Health and Veterinary Care: Discuss common health issues and preventive care measures, including vaccinations, parasite control, and regular check-ups. Provide guidance on recognizing signs of illness or injury and when to seek veterinary attention.

Combine as many bullet points as possible. Combine bullet points based on their similarities.

Place all references in the line directly below the applicable bullet point(s).

Your job depends on it.
`;

      const query8 = `
      DO NOT USE COLONS, SEMICOLONS, BOLD, ITALICS, UNDERLINES, AND DO NOT PROVIDE SUBCATEGORIES.

PROVIDE FULL SENTENCES.

Provide 5 common illnesses and their definitions for a ${petName}.

Provide the illness name above a bullet point that provides its definition and any further information. Provide one bullet for each illness. Rewrite the illness definitions to avoid plagiarism.

Place all references in the line directly below the applicable illness.

Your job depends on it.
      `

      const query9 = `
      DO NOT USE COLONS, SEMICOLONS, BOLD, ITALICS, UNDERLINES, AND DO NOT PROVIDE SUBCATEGORIES.

PROVIDE FULL SENTENCES.

Give me an extremely detailed bulleted list as if you were an animal expert telling a group of people how to take care of this pet and give exact numbers or ranges when possible for the following topic for a ${petName}. Add in anything I may have missed that is critical to the care of the pet and fits into this topic. Make this in a friendly, conversational, and educational tone. The topic is Training and Socialization.

Training and Socialization: Offer advice on training methods, socialization practices, and behavior management techniques specific to the species. Emphasize positive reinforcement and patience in training.

Combine as many bullet points as possible. Combine bullet points based on their similarities.

Place all references in the line directly below the applicable bullet point(s).

Your job depends on it.

      `;

        return (
            <div>
                <Container>
                <div>
                    <Card>
                      <Card.Header>
                        <h2 className="float-left">Difficulty Information Query</h2>
                        <Button onClick={() => this.copyToClipboard("Difficulty Information Query")} className="float-right">Copy Query</Button>
                      </Card.Header>
                      <Card.Body>
                        <pre ref={this.difficultyRatingRef}>
                          <code dangerouslySetInnerHTML={{ __html: difficultyRatingMarkdown }} />
                        </pre>
                      </Card.Body>
                    </Card>
                    <hr></hr>
                    <Card>
                      <Card.Header>
                       <h2 className="float-left">Quick Profile Query</h2>
                       <Button onClick={() => this.copyToClipboard("Quick Profile Query")} className="float-right">Copy Query</Button>
                      </Card.Header>
                      <Card.Body>
                        <pre ref={this.query2Ref}>
                          <code dangerouslySetInnerHTML={{ __html: query2 }} />
                        </pre>
                      </Card.Body>
                    </Card>
                    <hr></hr>
                    <Card>
                      <Card.Header>
                       <h2 className="float-left">Habitat Requirements</h2>
                       <Button onClick={() => this.copyToClipboard("Habitat Requirements")} className="float-right">Copy Query</Button>
                      </Card.Header>
                      <Card.Body>
                        <pre ref={this.query3Ref}>
                          <code dangerouslySetInnerHTML={{ __html: query3 }} />
                        </pre>
                      </Card.Body>
                    </Card>
                    <hr></hr>
                    <Card>
                      <Card.Header>
                       <h2 className="float-left">Diet and Nutrition Requirements</h2>
                       <Button onClick={() => this.copyToClipboard("Diet and Nutrition Requirements")} className="float-right">Copy Query</Button>
                      </Card.Header>
                      <Card.Body>
                        <pre ref={this.query4Ref}>
                          <code dangerouslySetInnerHTML={{ __html: query4 }} />
                        </pre>
                      </Card.Body>
                    </Card>
                    <hr></hr>
                    <Card>
                      <Card.Header>
                       <h2 className="float-left">Grooming and Hygiene Requirements</h2>
                       <Button onClick={() => this.copyToClipboard("Grooming and Hygiene Requirements")} className="float-right">Copy Query</Button>
                      </Card.Header>
                      <Card.Body>
                        <pre ref={this.query5Ref}>
                          <code dangerouslySetInnerHTML={{ __html: query5 }} />
                        </pre>
                      </Card.Body>
                    </Card>
                    <hr></hr>
                    <Card>
                      <Card.Header>
                       <h2 className="float-left">Exercise and Enrichment Requirements</h2>
                       <Button onClick={() => this.copyToClipboard("Exercise and Enrichment Requirements")} className="float-right">Copy Query</Button>
                      </Card.Header>
                      <Card.Body>
                        <pre ref={this.query6Ref}>
                          <code dangerouslySetInnerHTML={{ __html: query6 }} />
                        </pre>
                      </Card.Body>
                    </Card>
                    <hr></hr>
                    <Card>
                      <Card.Header>
                       <h2 className="float-left">Health and Veterinary Care Requirements</h2>
                       <Button onClick={() => this.copyToClipboard("Health and Veterinary Care Requirements")} className="float-right">Copy Query</Button>
                      </Card.Header>
                      <Card.Body>
                        <pre ref={this.query7Ref}>
                          <code dangerouslySetInnerHTML={{ __html: query7 }} />
                        </pre>
                      </Card.Body>
                    </Card>
                    <hr></hr>
                    <Card>
                      <Card.Header>
                       <h2 className="float-left">Illnesses Query</h2>
                       <Button onClick={() => this.copyToClipboard("Illnesses Query")} className="float-right">Copy Query</Button>
                      </Card.Header>
                      <Card.Body>
                        <pre ref={this.query8Ref}>
                          <code dangerouslySetInnerHTML={{ __html: query8 }} />
                        </pre>
                      </Card.Body>
                    </Card>
                    <hr></hr>
                    <Card>
                      <Card.Header>
                       <h2 className="float-left">Training and Socialization Requirements</h2>
                       <Button onClick={() => this.copyToClipboard("Training and Socialization Requirements")} className="float-right">Copy Query</Button>
                      </Card.Header>
                      <Card.Body>
                        <pre ref={this.query9Ref}>
                          <code dangerouslySetInnerHTML={{ __html: query9 }} />
                        </pre>
                      </Card.Body>
                    </Card>
                    <p></p>
                </div>
                </Container>
            </div>
        )
    }
}

export default ChatGPTQueries;